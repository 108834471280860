import React from 'react';
import CompanyListItem from './CompanyListItem';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingInfinityScroll from '../../components/loading-infinity-scroll/LoadingInfinityScroll';
import './styles.scss';

export const CompanyList = ({
  data = [],
  fetchMore,
  onHasMore,
}) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMore}
      hasMore={onHasMore}
      loader={<LoadingInfinityScroll />}
    >
      <div className="table-border">
        <table key="tablecall" className="table table-striped">
          <thead key="thead" className="thead-dark">
            <tr>
              <th key="name" width="50">Logo</th>
              <th key="name" width="200">Nome</th>
              <th key="cpf" width="140">CPF/CNPJ</th>
              <th key="email" width="200">Ativa?</th>
              <th key="mobileNumber" width="140">Seguidores</th>
              {/* <th key="actions" width="120">Ações</th> */}
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 && data.map((item, key) => {
              return (
                <CompanyListItem
                  key={key}
                  id={item._id}
                  logo={item.logo?.url}
                  name={item.name}
                  cnpj={item.cnpj}
                  isActived={item.isActived}
                  followerCount={item.followerCount}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </InfiniteScroll>
  );
};
