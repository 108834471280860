import React from 'react';
import { ReactComponent as LoadingSpinner } from '../../svg/ic_loading_spinner.svg';
import './styles.scss';

const LoadingInfinityScroll = () => (
  <div className="loading-infinity-scroll">
    <div className="loading-infinity-scroll__inner">
      <LoadingSpinner className="loading-infinity-scroll__inner__spinner" />
    </div>
  </div>
);

export default LoadingInfinityScroll;
