import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const COUNT = gql`
  query rootBankAccountCount {
    rootBankAccountCount
  }
`;

async function getBankAccountCount() {
  try {
    const data = await graphqlApi().query({
      query: COUNT,
    });
    return data.data.rootBankAccountCount;
  } catch (error) {
    /** */
  }
}

export default {
  getBankAccountCount,
};
