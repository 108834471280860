import React from 'react';
import number from '../../helpers/number'
import formatCurrency from '../../helpers/currency'
import Show from '../../components/show'
import parseDateTime from '../../helpers/parse-date-time'
import { ReactComponent as LoadingSpinner } from '../../svg/ic_loading_spinner.svg';
import { I18n } from 'react-redux-i18n';
import './styles.scss';

const STATUS = {
  P: "Pendente",
  D: "Finalizado",
  F: "Fraude",
  C: "Cancelado",
}

const WithdrawListItem = ({
  changeStatusLoading,
  id,
  createdAt,
  customerId,
  name,
  cpf,
  pix,
  balance,
  status,
  onDone,
  onFraud,
  onCancel,
}) => (
  <tr>
    <td>{parseDateTime(createdAt)}</td>
    <td>{name ? name : 'Usuário não existe'}</td>
    <td>
      <a href={`${I18n.t('routes.customer.url')}/${customerId}`} target="_blank">
        {cpf ? number.cpfMask(cpf) : '000.000.000-00'}
      </a>
    </td>
    <td>{pix}</td>
    <td>{formatCurrency(balance)}</td>
    <td>{STATUS[status]}</td>
    <td>
      <Show if={status == 'P' && !changeStatusLoading.includes(id)}>
        <button className="button_withdraw" onClick={() => onDone(id)}>
          Finalizar
        </button>
        <button className="button_withdraw_fraud" onClick={() => onFraud(id)}>
          Fraude
        </button>
        <button className="button_withdraw_cancel" onClick={() => onCancel(id)}>
          Cancelar
        </button>
      </Show>
      <Show if={status == 'P' && changeStatusLoading.includes(id)}>
        <LoadingSpinner className="loading-infinity-scroll__inner__spinner" />
      </Show>
    </td>
  </tr>
);

export default WithdrawListItem;
