import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { IoIosMenu} from 'react-icons/io';
import { getUser } from '../../app/redux/reducers/user';
import Sidebar from './Sidebar';
import './styles.scss';

const Painel = ({ container }) => {
  const { user } = useSelector(state => getUser(state));
  const [showSideBar, setShowSideBar] = useState(true);

  const toggleSidebar = () => {
    setShowSideBar(!showSideBar);
  }

  return (
    <div className={`painel__container ${!showSideBar && 'hide-sidebar'}`}>
      <Sidebar
        name={user?.name}
      />
      <div className="painel__content">
        <div className="painel__content__top">
          <div className="painel__content__top__left">
            <button
              className="painel__content__top__menu"
              onClick={() => toggleSidebar()}
            >
              <IoIosMenu />
            </button>
          </div>
          <div className="painel__content__top__right">
            <button
              className="painel__content__top__notifications"
              type="button"
              onClick={() => navigate(I18n.t('routes.notifications.url'))}
            >
              <img
                src="/assets/svg/dashboard_bell.svg"
                alt="Logo Dash"
              />
            </button>
          </div>
        </div>
        {container}
      </div>
    </div>
  );
};

export default Painel;
