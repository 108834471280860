import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const COMPANY = gql`
  query Company($id: ID!){
    company(id: $id) {
      _id
      name
      fancyName
      isActived
      logo {
        url
      }
      cnpj
      followerCount
    }
  }
`;

async function getCompany(id) {
  try {
    const data = await graphqlApi().query({
      query: COMPANY,
      variables: {
        id
      },
    });
    return data.data.company;
  } catch (error) {
    /** */
  }
}

const COMPANIES = gql`
  query companies($filter: [Filter], $sort: [Sort], $skip: Int, $take: Int) {
    companies(filter: $filter, sort: $sort, skip: $skip, take: $take) {
      _id
      name
      fancyName
      isActived
      logo {
        url
      }
      cnpj
      followerCount
    }
  }
`;

async function getCompanies(filter, skip, take) {
  try {
    const sort = [{ field: '_id', dir: 'desc' }];

    const data = await graphqlApi().query({
      query: COMPANIES,
      variables: {
        filter,
        sort,
        skip,
        take,
      },
    });
    return data.data.companies;
  } catch (error) {
    /** */
  }
}

const COMPANY_COUNT = gql`
  query rootCustomerCount {
    rootCustomerCount
  }
`;

async function getCompaniesCount() {
  try {
    const data = await graphqlApi().query({
      query: COMPANY_COUNT,
    });
    return data.data.rootCustomerCount;
  } catch (error) {
    /** */
  }
}

export default {
  getCompany,
  getCompanies,
  getCompaniesCount,
};
