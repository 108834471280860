import React from 'react';
import { useLocation } from '@reach/router';
import { I18n } from 'react-redux-i18n';

import './styles.scss';

const FormHeader = () => {
  const { pathname } = useLocation();

  const selectTitle = () => {
    let title;
    switch (pathname) {
      case I18n.t('routes.login.url'):
      case '/':
        title = 'Bem-vindo ao EloPag Business';
        break;
      case I18n.t('routes.forgotPassword.url'):
        title = 'Esqueceu Senha EloPag Business';
        break;
      case I18n.t('routes.register.url'):
        title = 'Criar Conta EloPag Business';
        break;
      default:
        break;
    }

    return title;
  };

  const selectPhrase = () => {
    let phrase;
    switch (pathname) {
      case I18n.t('routes.login.url'):
      case '/':
        phrase = `Um canal exclusivo para empresas que \n
                  geram privacidade, entre com seu \n
                  login e senha.`
        break;
      case I18n.t('routes.forgotPassword.url'):
        phrase = `Por favor digite seu endereço de \n
                  e-mail. Enviaremos um e-mail para \n
                  redefinir sua senha.`
        break;
      case I18n.t('routes.register.url'):
        phrase = `Digite o código de verificação que \n
                  acabamos de enviar para você no seu \n
                  número de telefone registrado.`
        break;
      default:
        break;
    }
    return phrase;
  };

  return (
    <section className="form-wrapper__section">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="form-wrapper__head col-md-8 col-lg-6 px-0">
            <div className="form-wrapper__header">
              <img
                src="/assets/images/logo.svg"
                alt="Logo EloPag"
                className="form-wrapper__header__logo"
              />
              <p className="form-wrapper__header__title">{selectTitle()}</p>
              <p className="form-wrapper__header__subtitle">{selectPhrase()}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FormHeader;
