import * as AuthActions from './auth';
import RootRequest from '../../api/root';

export const ACTION_SAVE_USER = 'SAVE_USER';
export const ACTION_CLEAN_USER = 'CLEAN_USER';

export const saveUser = user => ({
  type: ACTION_SAVE_USER,
  user,
});

export const cleanUser = () => ({
  type: ACTION_CLEAN_USER,
  user: null,
});

export const getUser = () => async (dispatch) => {
  try {
    const user = await RootRequest.getRoot();
    dispatch(saveUser(user));
  } catch (err) {
    dispatch(AuthActions.cleanAuth());
  } finally {
  }
};
