import React from 'react';
import CustomerActions from './CustomerActions'
import parseDateTime from '../../helpers/parse-date-time'
import number from '../../helpers/number'

const ListItem = ({
  id,
  name,
  cpf,
  email,
  refCode,
  referral,
  mobileNumber,
  createdAt,
}) => (
  <tr>
    <td>{name}</td>
    <td>{number.cpfMask(cpf)}</td>
    <td>{email}</td>
    <td>{number.mobileNumberMask(mobileNumber)}</td>
    <td>{refCode}</td>
    <td>{referral}</td>
    <td>{parseDateTime(createdAt)}</td>
    <td>
      <CustomerActions
        id={id}
      />
    </td>
  </tr>
);

export default ListItem;
