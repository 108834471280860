import React from "react";
import ContentLoader from "react-content-loader";

export const WithdrawSkeleton = () => (
  <ContentLoader
    height={'600'}
    width={'100%'}
    backgroundColor="#ecebeb"
    foregroundColor="#E6E6E6"
  >
    <rect x="0" y="10" rx="3" ry="3" width="100%" height="17" />
    <rect x="0" y="45" rx="3" ry="3" width="100%" height="17" />
    <rect x="0" y="22" rx="3" ry="3" width="5%" height="33" />
    <rect x="222" y="19" rx="3" ry="3" width="10%" height="33" />
    <rect x="544" y="20" rx="3" ry="3" width="8%" height="33" />
    <rect x="782" y="21" rx="3" ry="3" width="8%" height="33" />
    <rect x="calc(100% - 23px)" y="19" rx="3" ry="3" width="2%" height="33" />

    <circle cx="20" cy="100" r="20" />
    <rect x="50" y="90" rx="3" ry="3" width="calc(100% - 50px)" height="20" />

    <circle cx="20" cy="180" r="20" />
    <rect x="50" y="170" rx="3" ry="3" width="calc(100% - 50px)" height="20" />

    <circle cx="20" cy="260" r="20" />
    <rect x="50" y="250" rx="3" ry="3" width="calc(100% - 50px)" height="20" />

    <circle cx="20" cy="340" r="20" />
    <rect x="50" y="330" rx="3" ry="3" width="calc(100% - 50px)" height="20" />

    <circle cx="20" cy="420" r="20" />
    <rect x="50" y="410" rx="3" ry="3" width="calc(100% - 50px)" height="20" />

    <circle cx="20" cy="500" r="20" />
    <rect x="50" y="490" rx="3" ry="3" width="calc(100% - 50px)" height="20" />

    <circle cx="20" cy="580" r="20" />
    <rect x="50" y="570" rx="3" ry="3" width="calc(100% - 50px)" height="20" />
  </ContentLoader>
);
