import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const COUNT = gql`
  query rootAccountTransactionCount {
    rootAccountTransactionCount
  }
`;

async function getAccountTransactionCount() {
  try {
    const data = await graphqlApi().query({
      query: COUNT,
    });
    return data.data.rootAccountTransactionCount;
  } catch (error) {
    /** */
  }
}

const ACCOUNT_TRANSCTIONS = gql`
  query rootAccountTransactions($customerId: ID, $skip: Int, $take: Int) {
    rootAccountTransactions(customerId: $customerId, skip: $skip, take: $take) {
      _id
      description
      chat {
        _id
        company {
          _id
          name
        }
      }
      call {
        _id
        company {
          _id
          name
        }
      }
      article {
        _id
        company {
          _id
          name
        }
      }
      type
      value
      createdAt
    }
  }
`;

async function getAccountTransactions(customerId, skip, take) {
  try {
    const data = await graphqlApi().query({
      query: ACCOUNT_TRANSCTIONS,
      variables: {
        customerId,
        skip,
        take,
      },
    });
    return data?.data?.rootAccountTransactions;
  } catch (error) {
    /** */
  }
}

export default {
  getAccountTransactionCount,
  getAccountTransactions,
};
