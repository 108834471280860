import { useState, useEffect } from 'react'
import WithdrawApi from '../../app/api/withdraw';

const useWithdraw = () => {
  const [withdraws, setWithdraws] = useState([]);
  const [changeStatusLoading, setChangeStatusLoading] = useState([]);
  const [take] = useState(20);
  const [skip, setSkip] = useState(0);
  const [oldWithdrawsLength, setOldWithdrawsLength] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [filter, setFilter] = useState([]);

  const getWithdraws = async () => {
    setSkip(skip + take);
    return await WithdrawApi.getWithdraws(filter, skip, take);
  }

  const fetchMore = async () => {
    setOldWithdrawsLength(withdraws.length);
    const newList = await getWithdraws();
    setWithdraws([...withdraws, ...newList]);
    setSkip(skip + take);
  }

  const changeStatusWithdraw = async (id, status) => {
    setChangeStatusLoading([...changeStatusLoading, id])
    await WithdrawApi.changeStatusWithdraw(id, status);
    const index = changeStatusLoading.indexOf(id)
    const newChangeStatusLoading = changeStatusLoading.splice(index, 1)

    const list = await WithdrawApi.getWithdraws([], 0, take);
    setChangeStatusLoading(newChangeStatusLoading)
    setWithdraws(list);
    setOldWithdrawsLength(0)
    setSkip(take)
  }

  const onSearch = async (filterForm) => {
    const filterData = []
    for (const [key, value] of Object.entries(filterForm)) {
      if (!!value) {
        filterData.push({
          field: key,
          value: value,
          operator: 'contains',
        })
      }
    }
    setFilter(filterData)
    setSkip(20);
    setOldWithdrawsLength(0)
    const list = await WithdrawApi.getWithdraws(filterData, 0, take);
    setWithdraws(list);
  }

  useEffect(() => {
    async function init() {
      const list = await getWithdraws();
      setWithdraws(list);
    }
    init()
  }, []);

  useEffect(() => {
    const diffWithdrawsLength = withdraws?.length - oldWithdrawsLength;
    setHasMore((diffWithdrawsLength) % 10 === 0 && diffWithdrawsLength > 0)
  }, [withdraws]);

  return { withdraws, changeStatusLoading, hasMore, fetchMore, getWithdraws, changeStatusWithdraw, onSearch }
}

export default useWithdraw
