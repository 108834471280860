import React from 'react';
import { IoIosAdd } from 'react-icons/io';
import Show from  '../show';
import './styles.scss';

function Header({title, subTitle, onClickAdd}) {
  return (
    <div className="header-container">
      <div className="header-container__title-container">
        <p className="header-container__page-title">
          {title}
        </p>
        <p className="header-container__page-results">
          {subTitle}
        </p>
      </div>
      <Show if={onClickAdd}>
        <div className="header-container__button-container">
          <button
            type="button"
            onClick={onClickAdd}
            className="btn-primary"
          >
            <IoIosAdd />
            Nova
          </button>
        </div>
      </Show>
    </div>
  );
}

export default Header;