/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import './styles.scss';

export function WithdrawFilter({ onSearch }) {
  const [filter, setFilter] = useState({
    'customer.name': '',
    'customer.cpf': '',
    status: '',
  })

  return (
    <div className="filter">
      <div className="filter__form__field">
        <span className="filter__form__field__label">Nome</span>
        <input
          className="filter__form__field__input"
          type="text"
          placeholder="Nome"
          maxLength="150"
          required
          value={filter['customer.name']}
          onChange={e => setFilter({ ...filter, ['customer.name']: e.target.value })}
        />
      </div>

      <div className="filter__form__field">
        <span className="filter__form__field__label">CPF</span>
        <input
          className="filter__form__field__input"
          type="text"
          placeholder="CPF"
          maxLength="150"
          required
          value={filter['customer.cpf']}
          onChange={e => setFilter({ ...filter, ['customer.cpf']: e.target.value.replace(/[^0-9]/g, '') })}
        />
      </div>

      <div className="filter__form__field">
        <span className="filter__form__field__label">Status</span>
        <select
          className="filter__form__field__input"
          type="text"
          placeholder="Status"
          maxLength="150"
          required
          value={filter.status}
          onChange={e => setFilter({ ...filter, ['status']: e.target.value })}
        >
          <option value="">Selecionar</option>
          <option value="P">Pendente</option>
          <option value="D">Finalizado</option>
          <option value="F">Fraude</option>
          <option value="C">Cancelado</option>
        </select>
      </div>

      <button
        type="button"
        className="btn-primary filter__form__button"
        onClick={() => onSearch(filter)}
      >
        Buscar
      </button>
    </div>
  );
}
