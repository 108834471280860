import { useState, useEffect } from 'react'
import CustomerApi from '../../app/api/customer';
import AccountTransactionApi from '../../app/api/accountTransaction';
import BankAccountApi from '../../app/api/bankAccount';
import WithdrawApi from '../../app/api/withdraw';

const useCustomer = () => {
  const [customerQty, setCustomerQty] = useState(0);
  const [accountTransactionQty, setAccountTransactionQty] = useState(0);
  const [bankAccountQty, setBankAccountQty] = useState(0);
  const [withdrawPendenceQty, setWithdrawPendenceQty] = useState(0);
  const [withdrawDoneQty, setWithdrawDoneQty] = useState(0);

  const getCustomerCount = async () => {
    const count = await CustomerApi.getCustomerCount();
    setCustomerQty(count);
  }

  const getAccountTransactionCount = async () => {
    const count = await AccountTransactionApi.getAccountTransactionCount();
    setAccountTransactionQty(count);
  }

  const getBankAccountCount = async () => {
    const count = await BankAccountApi.getBankAccountCount();
    setBankAccountQty(count);
  }

  const getWithdrawPendenceCount = async () => {
    const count = await WithdrawApi.getWithdrawPendenceCount();
    setWithdrawPendenceQty(count);
  }

  const getWithdrawDoneCount = async () => {
    const count = await WithdrawApi.getWithdrawDoneCount();
    setWithdrawDoneQty(count);
  }

  useEffect(() => {
    getCustomerCount()
    getAccountTransactionCount()
    getBankAccountCount()
    getWithdrawPendenceCount()
    getWithdrawDoneCount()
  }, []);

  return { customerQty, accountTransactionQty, bankAccountQty, withdrawPendenceQty, withdrawDoneQty }
}

export default useCustomer
