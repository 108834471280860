export default {
  application: {
    name: 'EloPag | Conexão Monetizada',
  },
  routes: {
    login: {
      url: '/login',
      pageTitle: 'Login',
    },
    dashboard: {
      url: '/dashboard',
      pageTitle: 'Dashboard',
    },
    customer: {
      url: '/clientes',
      pageTitle: 'Clientes',
    },
    customerNew: {
      url: '/clientes/new',
      pageTitle: 'Clientes',
    },
    company: {
      url: '/empresas',
      pageTitle: 'Empresas',
    },
    payment: {
      url: '/pagamentos',
      pageTitle: 'Pagamentos',
    },
    withdraw: {
      url: '/saques',
      pageTitle: 'Solicitação de Saque',
    },
  },
};
