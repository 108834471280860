import React, { useEffect, useState } from 'react';
import { useParams } from '@reach/router';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Header from '../../components/header/Header';
import useCustomer from './useCustomer'
import parseDateTime from '../../helpers/parse-date-time'
import number from '../../helpers/number'
import './styles.scss';
import { AccountTransactionList } from './AccountTransactionList';
import Show from '../../components/show';
import { CustomerList } from './CustomerList';
import formatCurrency from '../../helpers/currency'

export default function CustomerForm() {
  const { id } = useParams();
  const { customer, accountTransactions, referrals, getCustomer } = useCustomer();
  const [active, setActive] = useState(1);
  const [balance, setBalance] = useState(0);

  const getBalance = (transactions = []) => {
    let balance = 0;
    transactions.forEach(transaction => {
      if (transaction.type === 'C') balance += parseFloat(transaction.value)
      else if (transaction.type === 'D') balance -= parseFloat(transaction.value)
    })
    return balance
  } 

  useEffect(() => {
    getCustomer(id)
  }, [])

  useEffect(() => {
    if (accountTransactions.length) {
      const b = getBalance(accountTransactions);
      setBalance(b)
    }
  }, [accountTransactions])

  return (
    <div className="page-container container-fluid">
      <Header
        title={'Detalhes do Cliente'}
        subTitle={'Todos os detalhes dos clientes'}
      />
      <div className="row">
        <div className="col-sm">
          <div>
            <b>Nome</b>
          </div>
          {customer?.name}
        </div>
        <div className="col-sm">
          <div>
            <b>CPF</b>
          </div>
          {number.cpfMask(customer?.cpf)}
        </div>
        <div className="col-sm">
          <div>
            <b>E-mail</b>
          </div>
          {customer?.email}
        </div>
        <div className="col-sm">
          <div>
            <b>Celular</b>
          </div>
          {number.mobileNumberMask(customer?.mobileNumber)}
        </div>
        <div className="col-sm">
          <div>
            <b>Data de Cadastro</b>
          </div>
          {parseDateTime(customer?.user?.createdAt)}
        </div>

      </div>
      <br />
      <div className="row">
        <div className="col-sm">
          <div>
            <b>RefCode</b>
          </div>
          {customer?.refCode}
        </div>
        <div className="col-sm">
          <div>
            <b>Indicação</b>
          </div>
          {customer?.referral}
        </div>
        <div className="col-sm"></div>
        <div className="col-sm"></div>
        <div className="col-sm"></div>
      </div>
      <br />
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a className={`nav-link ${active == 1 ? 'active' : ''}`} onClick={() => setActive(1)}>Extrato/Saldo - ({formatCurrency(balance)})</a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${active == 2 ? 'active' : ''}`} onClick={() => setActive(2)}>Indicações({referrals.length})</a>

        </li>
        {/* <li className="nav-item">
          <a className={`nav-link ${active == 3 ? 'active' : ''}`} onClick={() => setActive(3)}>Solicitações de saque</a>
        </li> */}
      </ul>

      <Show if={active == 1}>
        <AccountTransactionList
          data={accountTransactions}
        />
      </Show>

      <Show if={active == 2}>
        <CustomerList
          data={referrals}
        />
      </Show>
    </div>
  );
}
