import moment from 'moment';
import EmailValidator from 'email-validator';
import StringHelper from './string';

const validateCNPJ = (cnpj) => {
  const verifierDigit = (numbers) => {
    let index = 2;
    const reverse = numbers.split('').reduce((buffer, number) => [parseInt(number, 10)].concat(buffer), []);

    const sum = reverse.reduce((buffer, number) => {
      buffer += number * index;
      index = (index === 9 ? 2 : index + 1);
      return buffer;
    }, 0);

    const mod = sum % 11;
    return (mod < 2 ? 0 : 11 - mod);
  };

  const stripped = cnpj.replace(/[^\d]+/g, '');

  if (stripped === '') return false;

  // CNPJ must be defined
  if (!stripped) { return false; }

  // CNPJ must have 14 chars
  if (stripped.length !== 14) { return false; }

  // CNPJ can't be blacklisted
  if ([
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ].indexOf(stripped) >= 0) { return false; }

  let numbers = stripped.substr(0, 12);
  numbers += verifierDigit(numbers);
  numbers += verifierDigit(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
};

const validateCPF = (cpf) => {
  if (cpf === '' || !(/^\d+$/.test(cpf))) return false;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || cpf === '00000000000' || cpf === '11111111111' || cpf === '22222222222'
    || cpf === '33333333333' || cpf === '44444444444' || cpf === '55555555555' || cpf === '66666666666'
    || cpf === '77777777777' || cpf === '88888888888' || cpf === '99999999999') { return false; }
  let add = 0;
  for (let i = 0; i < 9; i += 1) { add += parseInt(cpf.charAt(i), 10) * (10 - i); }
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) { rev = 0; }
  if (rev !== parseInt(cpf.charAt(9), 10)) { return false; }
  add = 0;
  for (let i = 0; i < 10; i += 1) { add += parseInt(cpf.charAt(i), 10) * (11 - i); }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) { rev = 0; }
  if (rev !== parseInt(cpf.charAt(10), 10)) { return false; }
  return true;
};

const validateDocument = (type, value) => {
  const valueNumbers = StringHelper.keepOnlyNumber(value);
  switch (type) {
    case 'cnpj':
      return validateCNPJ(valueNumbers);
    case 'cpf':
      return validateCPF(valueNumbers);
    default:
  }
};

const validateEmail = email => EmailValidator.validate(email);
// if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) return true;
// return false;
// };

const validateBirthday = (date) => {
  if (date.indexOf('_') > 0) {
    return false;
  }
  if (moment(date).format('YYYY') < 1910) {
    return false;
  }
  if (!moment(date, 'DD/MM/YYYY').isValid()) {
    return false;
  }
  if (moment().isAfter(moment(date, 'DD/MM/YYYY'))) {
    return false;
  }
  return true;
};

const validadeCardExpirationData = (date) => {
  if (moment(date, 'MM/YYYY').format('YYYY') < 2019) {
    return false;
  }
  if (moment(date, 'MM/YYYY').isAfter(moment().add('20', 'years'))) {
    return false;
  }
  if (!moment(date, 'MM/YYYY').isValid()) {
    return false;
  }
  if (moment().isAfter(moment(date, 'MM/YYYY'))) {
    return false;
  }
  return true;
};

const validatePhone = (phone) => {
  if (phone.indexOf('_') > 0) {
    return false;
  }
  return true;
};

export {
  validateDocument,
  validateCNPJ,
  validateCPF,
  validateEmail,
  validateBirthday,
  validatePhone,
  validadeCardExpirationData,
};
