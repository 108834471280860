import React from 'react';
import './styles.scss';
import useWithdraw from './useWithdraw'
import Header from '../../components/header/Header';
import { WithdrawList } from './WithdrawList';
import { WithdrawSkeleton } from './WithdrawSkeleton';
import Show from '../../components/show';
import { WithdrawFilter } from './WithdrawFilter';

export default function Withdraw() {
  const { withdraws, changeStatusLoading, hasMore, fetchMore, changeStatusWithdraw, onSearch } = useWithdraw();

  return (
    <div className="page-container container-fluid">
      <Header
        title="Solicitação de Saque"
        subTitle={`Exibindo um total de ${withdraws?.length} Solicitações de Saque`}
      />
      <WithdrawFilter onSearch={(f) => onSearch(f)} />
      <Show if={withdraws?.length > 0}>
        <WithdrawList
          data={withdraws}
          changeStatusLoading={changeStatusLoading}
          fetchMore={fetchMore}
          onHasMore={hasMore}
          onDone={(id) => changeStatusWithdraw(id, 'D')}
          onFraud={(id) => changeStatusWithdraw(id, 'F')}
          onCancel={(id) => changeStatusWithdraw(id, 'C')}
        />
      </Show>
      <Show if={withdraws?.length <= 0}>
        <WithdrawSkeleton />
      </Show>
    </div>
  );
}
