/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import useDashboard from './useDashboard'
import './styles.scss';

const Dashboard = () => {
  const {
    customerQty,
    accountTransactionQty,
    bankAccountQty,
    withdrawPendenceQty,
    withdrawDoneQty 
  } = useDashboard()

  const countFormat = (value) => {
    const size = (value.toString()).length
    if (size == 5) {
      return Math.abs(Number(value)).toPrecision(3) / 1.0e+3 + "K"
    }
    if (size == 6) {
      return Math.abs(Number(value)).toPrecision(4) / 1.0e+3 + "K"
    }
    if (size == 7 || size == 8) {
      return Math.abs(Number(value)).toPrecision(3) / 1.0e+6 + "M"
    }
    if (size == 9) {
      return Math.abs(Number(value)).toPrecision(4) / 1.0e+6 + "M"
    }

    return value;
  }

  return (
    <div className="page-container container cards-container">
      <div className="dashboard__cards-container">
        <div
          className="dashboard__cards-container__card"
          style={{filter: `hue-rotate(${Math.floor(Math.random() * 30)}deg)`}}
        >
          <div className="dashboard__cards-container__total-number">
            {countFormat(customerQty)}
          </div>
          <div className="dashboard__cards-container__title">
            Clientes
          </div>
          <button
            type="button"
            className="dashboard__cards-container__button"
            onClick={() => navigate(`${I18n.t('routes.customer.url')}`)}
          >
            VER CLIENTES
          </button>
        </div>

        <div
          className="dashboard__cards-container__card"
          style={{filter: `hue-rotate(${Math.floor(Math.random() * 30)}deg)`}}
        >
          <div className="dashboard__cards-container__total-number">
            {countFormat(accountTransactionQty)}
          </div>
          <div className="dashboard__cards-container__title">
            Transações
          </div>
        </div>

        <div
          className="dashboard__cards-container__card"
          style={{filter: `hue-rotate(${Math.floor(Math.random() * 30)}deg)`}}
        >
          <div className="dashboard__cards-container__total-number">
            {countFormat(bankAccountQty)}
          </div>
          <div className="dashboard__cards-container__title">
            PIX Cadastrados
          </div>
        </div>

        <div
          className="dashboard__cards-container__card"
          style={{filter: `hue-rotate(${Math.floor(Math.random() * 30)}deg)`}}
        >
          <div className="dashboard__cards-container__total-number">
            {withdrawPendenceQty}
          </div>
          <div className="dashboard__cards-container__title">
            Saques Pendentes
          </div>
          <button
            type="button"
            className="dashboard__cards-container__button"
            onClick={() => navigate(`${I18n.t('routes.withdraw.url')}`)}
          >
            VER SAQUES
          </button>
        </div>

        <div
          className="dashboard__cards-container__card"
          style={{filter: `hue-rotate(${Math.floor(Math.random() * 30)}deg)`}}
        >
          <div className="dashboard__cards-container__total-number">
            {withdrawDoneQty}
          </div>
          <div className="dashboard__cards-container__title">
            Saques Finalizados
          </div>
          <button
            type="button"
            className="dashboard__cards-container__button"
            onClick={() => navigate(`${I18n.t('routes.withdraw.url')}`)}
          >
            VER SAQUES
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
