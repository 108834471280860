import React from 'react';
import { navigate, useLocation } from '@reach/router';
import { IoMdExit } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { logout } from '../../app/redux/actions/auth';
import SidebarLinks from './SidebarLinks';
import './styles.scss';

const Sidebar = ({
  name,
}) => {
  let { pathname } = useLocation();
  const dispatch = useDispatch();

  if (pathname.indexOf('/', 1) > 0) {
    [, pathname] = pathname.split('/');
    pathname = `/${pathname}`;
  }

  const handleLogoutClick = async () => {
    await dispatch(logout());
    navigate(I18n.t('routes.login.url'));
  };

  return (
    <div className="painel__side">
      <div className="painel__side__details-container">
        <div className="painel__side__details-image">
          {/* <img src={companyLogo} /> */}
        </div>
        <p className="painel__side__details-name">
          {name}
        </p>
      </div>
      {SidebarLinks.map(item => (
        <button
          type="button"
          className={`painel__side__container-buttons${pathname === I18n.t(item.url) ? '--selected' : ''}`}
          onClick={() => navigate(I18n.t(item.url))}
          key={item.name}
        >
          <item.icon className={`painel__side__container-logo${pathname === I18n.t(item.url) ? '--selected' : ''}`} />
          <p className={`painel__side__button-title${pathname === I18n.t(item.url) ? '--selected' : ''}`}>
            {I18n.t(item.name)}
          </p>
        </button>
      ))}

      <button
        type="button"
        className="painel__side__container-buttons"
        onClick={handleLogoutClick}
      >
        <IoMdExit className="painel__side__container-logo painel__side__container-logo--logout" />
        <p className="painel__side__button-title">
          Sair
        </p>
      </button>
    </div>
  );
};

export default Sidebar;
