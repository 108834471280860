import React from 'react';
import WithdrawListItem from './WithdrawListItem';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingInfinityScroll from '../../components/loading-infinity-scroll/LoadingInfinityScroll';
import './styles.scss';

export const WithdrawList = ({
  data = [],
  changeStatusLoading,
  fetchMore,
  onHasMore,
  onDone,
  onFraud,
  onCancel,
}) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMore}
      hasMore={onHasMore}
      loader={<LoadingInfinityScroll />}
    >
      <div className="table-border">
        <table key="tablecall" className="table table-striped">
          <thead key="thead" className="thead-dark">
            <tr>
              <th key="createdAt" width="100">Data</th>
              <th key="name" width="100">Nome</th>
              <th key="cpf" width="140">CPF</th>
              <th key="pix" width="240">Chave Pix</th>
              <th key="balance" width="100">Saldo</th>
              <th key="status" width="100">Status</th>
              <th key="actions" width="350">Ações</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 && data.map((item, key) => {
              if (!item) return null;
              
              return (
                <WithdrawListItem
                  key={key}
                  changeStatusLoading={changeStatusLoading}
                  id={item._id}
                  createdAt={item.createdAt}
                  customerId={item.customer?._id}
                  name={item.customer?.name}
                  cpf={item.customer?.cpf}
                  pix={item.bankAccount.pix}
                  balance={item.value}
                  status={item.status}
                  onDone={onDone}
                  onFraud={onFraud}
                  onCancel={onCancel}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </InfiniteScroll>
  );
};
