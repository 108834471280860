import { I18n } from 'react-redux-i18n';

const keepOnlyNumber = value => value.replace(/\D/g, '');

const countDecimals = (value) => {
  if (Math.floor(value) === value) return 0;
  return value ? value.toString().split('.')[1].length : 0;
};

function cepMask(cep) {
  const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;

  if (re.test(cep)) {
    return cep.replace(re, '$1.$2-$3');
  }
  return I18n.t('invalidCep');
}

const boletoMask = (boleto) => {
  const boletoNumberRegex = /^([\d]{5})([\d]{5})([\d]{5})([\d]{6})([\d]{5})([\d]{6})([\d])([\d]{14})/;
  return boleto.replace(boletoNumberRegex, '$1.$2 $3.$4 $5.$6 $7 $8');
};

const cpfMask = (cpf = '') => cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

const mobileNumberMask = (mobileNumber = '') => {
  const digitos = !mobileNumber ? '' : mobileNumber.replace(/[^\d]/g, '');
  if (!digitos || digitos.length < 10) return mobileNumber;
  const corte = digitos.length === 10 ? 6 : 7;
  const max = digitos.length > 11 ? 11 : digitos.length;
  return `(${digitos.substring(0, 2)}) ${digitos.substring(2, corte)}-${digitos.substring(corte, max)}`;
}

export default {
  keepOnlyNumber,
  countDecimals,
  cepMask,
  cpfMask,
  mobileNumberMask,
  boletoMask,
};
