import React from 'react';

import './styles.scss';

export default function FormWrapper({ children }) {
  return (
    <main className="form-wrapper">
      {children}
    </main>
  );
}
