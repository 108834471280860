/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import './styles.scss';

export function CompanyFilter({ onSearch }) {
  const [filter, setFilter] = useState({
    cnpj: '',
    name: '',
  })

  return (
    <div className="filter">
      <div className="filter__form__field">
        <span className="filter__form__field__label">CPF</span>
        <input
          className="filter__form__field__input"
          type="text"
          placeholder="CPF/CNPJ"
          maxLength="150"
          required
          value={filter.cnpj}
          onChange={e => setFilter({ ...filter, ['cnpj']: e.target.value })}
        />
      </div>

      <div className="filter__form__field">
        <span className="filter__form__field__label">Nome</span>
        <input
          className="filter__form__field__input"
          type="text"
          placeholder="Nome"
          maxLength="150"
          required
          value={filter.name}
          onChange={e => setFilter({ ...filter, ['name']: e.target.value })}
        />
      </div>

      <button
        type="button"
        className="btn-primary filter__form__button"
        onClick={() => onSearch(filter)}
      >
        Buscar
      </button>
    </div>
  );
}
