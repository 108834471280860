import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { split } from 'apollo-link';

import AuthService from './auth';
import api from '../../helpers/get-api-url';

let client;

export const graphqlInit = () => {
  const auth = AuthService.get();

  const httpLink = new HttpLink({
    uri: api.http,
    headers: {
      authorization: auth && `Bearer ${auth.token}`,
    }
  });
  
  const wsLink = new WebSocketLink({
    uri: api.ws,
    options: {
      reconnect: true,
      connectionParams: {
        authorization: auth && `Bearer ${auth.token}`,
      },
    }
  });
  
  const link = split(
    ({query}) => {
      const {kind, operation} = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  );
  
  const cache = new InMemoryCache();
  
  client = new ApolloClient({
    cache,
    link,
    name: 'react-web-client',
    version: '1.3',
    queryDeduplication: false,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });
}

graphqlInit();

const getGraphqlInstance = () => {
  return client;
};

export default getGraphqlInstance;