import React from 'react';
import CustomerListItem from './CustomerListItem';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingInfinityScroll from '../../components/loading-infinity-scroll/LoadingInfinityScroll';
import './styles.scss';

export const CustomerList = ({
  data = [],
  fetchMore,
  onHasMore,
}) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMore}
      hasMore={onHasMore}
      loader={<LoadingInfinityScroll />}
    >
      <div className="table-border">
        <table key="tablecall" className="table table-striped">
          <thead key="thead" className="thead-dark">
            <tr>
              <th key="name" width="200">Nome</th>
              <th key="cpf" width="140">CPF</th>
              <th key="email" width="200">E-mail</th>
              <th key="mobileNumber" width="140">Celular</th>
              <th key="refCode" width="150">RefCode</th>
              <th key="referral" width="150">Indicação</th>
              <th key="datime" width="100">Data</th>
              <th key="actions" width="100">Ações</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 && data.map((item, key) => {
              return (
                <CustomerListItem
                  key={key}
                  id={item._id}
                  name={item.name}
                  cpf={item.cpf}
                  email={item.email}
                  mobileNumber={item.mobileNumber}
                  refCode={item.refCode}
                  referral={item.referral}
                  createdAt={item.user.createdAt}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </InfiniteScroll>
  );
};
