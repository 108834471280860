import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const GET_ROOT = gql`
  query root {
    root {
      _id
      name
      email
    }
  }
`;

async function getRoot() {
  try {
    const { data: { root } } = await graphqlApi().mutate({
      mutation: GET_ROOT,
    });
    return root;
  } catch (e) {
    return e;
  }
}

export default {
  getRoot,
};
