import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const LOGIN_ROOT = gql`
  mutation LoginRoot(
    $email: String!
    $password: String!
  ){
    loginRoot(
      data: {
        email: $email
        password: $password
      }
    ){
      token
      user {
        _id
        name
        email
      }
    }
  }
`;

async function loginRoot(email, password) {
  try {
    const request = await graphqlApi().mutate({
      mutation: LOGIN_ROOT,
      variables: {
        email,
        password,
      },
    });
    return request.data.loginRoot;
  } catch (err) {
    const code = err.graphQLErrors.length > 0 ? err.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      WRONG_PASSWORD: 'Senha incorreta.',
      USER_NOT_FOUND: 'E-mail não cadastrado.',
      INACTIVE_ACCOUNT: `Ops, parece que sua conta ainda não foi ativada.
       Por favor verifique seu e-mail para continuar!`,
      INACTIVE_ACCOUNT_RESEND: `Ops, parece que sua conta ainda não foi ativada.
       Reenviamos um e-mail de validação!`,
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

export default {
  loginRoot,
};
