import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const WITHDRAWS = gql`
  query rootWithdraws($filter: [Filter], $sort: [Sort], $skip: Int, $take: Int) {
    rootWithdraws(filter: $filter, sort: $sort, skip: $skip, take: $take) {
      _id
      customer {
        _id
        name
        cpf
      }
      bankAccount {
        _id
        pix
      }
      value
      status
      createdAt
    }
  }
`;

async function getWithdraws(filter, skip, take) {
  try {
    const sort = [
      { field: 'status', dir: 'desc' },
      { field: 'createdAt', dir: 'asc' },
    ];

    const data = await graphqlApi().query({
      query: WITHDRAWS,
      variables: {
        filter,
        sort,
        skip,
        take,
      },
    });
    return data.data.rootWithdraws;
  } catch (error) {
    /** */
  }
}

export const WITHDRAW_CHANGE_STATUS = gql`
  mutation rootUpdateWithdraw($id: ID!, $status: String!) {
    rootUpdateWithdraw(
      data: {
        _id: $id
        status: $status,
      }
    ) {
      _id
    }
  }
`;

async function changeStatusWithdraw(id, status) {
  try {
    const data = await graphqlApi().mutate({
      mutation: WITHDRAW_CHANGE_STATUS,
      variables: {
        id,
        status,
      }
    });
    return data.data.rootUpdateWithdraw;
  } catch (error) {
    /** */
  }
}

const WITHDRAW_PENDENCE_COUNT = gql`
  query rootWithdrawCountPendence {
    rootWithdrawCountPendence
  }
`;

async function getWithdrawPendenceCount() {
  try {
    const data = await graphqlApi().query({
      query: WITHDRAW_PENDENCE_COUNT,
    });
    return data.data.rootWithdrawCountPendence;
  } catch (error) {
    /** */
  }
}

const WITHDRAW_DONE_COUNT = gql`
  query rootWithdrawCountDone {
    rootWithdrawCountDone
  }
`;

async function getWithdrawDoneCount() {
  try {
    const data = await graphqlApi().query({
      query: WITHDRAW_DONE_COUNT,
    });
    return data.data.rootWithdrawCountDone;
  } catch (error) {
    /** */
  }
}

export default {
  getWithdraws,
  changeStatusWithdraw,
  getWithdrawPendenceCount,
  getWithdrawDoneCount,
};
