import React from 'react';
import './styles.scss';

export default function FormFooter({ onFormSubmit, buttonTitle }) {
  return (
    <section className="form-wrapper__section form-wrapper__section__form-footer">
      <div className="container">
        <button
          type="submit"
          onClick={onFormSubmit}
          className="form-wrapper__container__form__button"
        >
          {buttonTitle}
        </button>
      </div>
    </section>
  );
}
