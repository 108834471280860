import AuthRequests from '../../api/auth';
import AuthService from '../../services/auth';

import * as UserActions from './user';

import { graphqlInit } from '../../services/graphql-api'

export const ACTION_AUTH_LOGIN = 'LOGIN';
export const ACTION_AUTH_LOGOUT = 'LOGOUT';

export const resetAuthentication = () => ({
  type: ACTION_AUTH_LOGOUT,
});

export const saveAuthentication = authData => ({
  type: ACTION_AUTH_LOGIN,
  auth: authData,
});

export const cleanAuth = () => async (dispatch) => {
  dispatch(resetAuthentication());
  dispatch(UserActions.cleanUser());
  AuthService.reset();
};

export const authenticate = (email, password) => async (dispatch) => {
  try {
    const { token, user } = await AuthRequests.loginRoot(email, password);
    AuthService.create({ token, id: user._id });
    graphqlInit();
    await dispatch(saveAuthentication(token));
    await dispatch(UserActions.getUser());
  } catch (err) {
    throw new Error(err.message);
  }
};

export const logout = () => async (dispatch) => {
  dispatch(cleanAuth());
};
