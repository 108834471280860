import { useState, useEffect } from 'react'
import CustomerApi from '../../app/api/customer';
import AccountTransactionApi from '../../app/api/accountTransaction';

const useCustomer = () => {
  const [customer, setCustomer] = useState();
  const [customers, setCustomers] = useState([]);
  const [take] = useState(20);
  const [skip, setSkip] = useState(0);
  const [oldCustomersLength, setOldCustomersLength] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [filter, setFilter] = useState([]);
  const [accountTransactions, setAccountTransactions] = useState([]);
  const [referrals, setReferrals] = useState([]);

  const getCustomer = async (id) => {
    const data = await CustomerApi.getCustomer(id);
    setCustomer(data)
  }

  const getCustomers = async () => {
    setSkip(20);
    return await CustomerApi.getCustomers(filter, skip, take);
  }

  const getAccountTransactions = async () => {
    const at = await AccountTransactionApi.getAccountTransactions(customer._id, 0, 10000);
    setAccountTransactions(at)
  }

  const getReferrals = async () => {
    const refs = await CustomerApi.getCustomers([{ field: 'referral', value: customer.refCode }], 0, 10000);
    setReferrals(refs)
  }

  const fetchMore = async () => {
    setOldCustomersLength(customers.length);
    const newList = await getCustomers();
    setCustomers([...customers, ...newList]);
    setSkip(skip + take);
  }

  const onSearch = async (filterForm) => {
    const filterData = []
    for (const [key, value] of Object.entries(filterForm)) {
      if (!!value) {
        filterData.push({
          field: key,
          value: value,
          operator: 'contains',
        })
      }
    }
    setFilter(filterData)
    setSkip(20);
    setOldCustomersLength(0)
    const list = await CustomerApi.getCustomers(filterData, 0, take);
    setCustomers(list);
  }

  useEffect(() => {
    async function init() {
      const list = await getCustomers();
      setCustomers(list);
    }
    init()
  }, []);

  useEffect(() => {
    const diffCustomersLength = customers?.length - oldCustomersLength;
    setHasMore((diffCustomersLength) % 10 === 0 && diffCustomersLength > 0)
  }, [customers]);

  useEffect(() => {
    if (customer) {
      getReferrals();
      getAccountTransactions()
    }
  }, [customer]);

  return { customer, customers, hasMore, accountTransactions, referrals, fetchMore, getCustomer, getCustomers, onSearch }
}

export default useCustomer
