import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingInfinityScroll from '../../components/loading-infinity-scroll/LoadingInfinityScroll';
import parseDateTime from '../../helpers/parse-date-time'
import formatCurrency from '../../helpers/currency'
import { I18n } from 'react-redux-i18n';
import './styles.scss';

export const AccountTransactionList = ({
  data = [],
  fetchMore,
  onHasMore,
}) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMore}
      hasMore={onHasMore}
      loader={<LoadingInfinityScroll />}
    >
      <div className="table-border">
        <table key="tablecall" className="table table-striped">
          <thead key="thead" className="thead-dark">
            <tr>
              <th key="createdAt" width="120">Data</th>
              <th key="description" width="300">Descrição</th>
              <th key="origin">Origem</th>
              <th key="value" width="120">Valor</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 && data.map((item, key) => {
              return (
                <tr key={key}>
                  <td>{parseDateTime(item?.createdAt)}</td>
                  <td>{item?.description}</td>
                  <td>
                    {item.chat ? item?.chat?.company ? item?.chat?.company?.name : 'Empresa não encontrada' : ''}
                    {item.call ? item?.call?.company ? item?.call?.company?.name : 'Empresa não encontrada' : ''}
                    {item.article ? item?.article?.company ? item?.article?.company?.name : 'Empresa não encontrada' : ''}
                  </td>
                  <td style={{ color: item?.type == 'D' ? '#aa3c3c' : '#41a95c', fontWeight: 600 }}>{item?.type == 'D' ? '- ' : ''}{formatCurrency(item?.value)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </InfiniteScroll>
  );
};
