import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const CUSTOMER = gql`
  query rootCustomer($id: ID) {
    rootCustomer(id: $id) {
      _id
      name
      cpf
      email
      gender
      birthdate
      mobileNumber
      refCode
      user {
        createdAt
      }
    }
  }
`;

async function getCustomer(id) {
  try {
    const data = await graphqlApi().query({
      query: CUSTOMER,
      variables: {
        id
      },
    });
    return data.data.rootCustomer;
  } catch (error) {
    /** */
  }
}

const CUSTOMERS = gql`
  query rootCustomers($filter: [Filter], $sort: [Sort], $skip: Int, $take: Int) {
    rootCustomers(filter: $filter, sort: $sort, skip: $skip, take: $take) {
      _id
      name
      cpf
      email
      gender
      birthdate
      mobileNumber
      refCode
      referral
      user {
        createdAt
      }
    }
  }
`;

async function getCustomers(filter, skip, take) {
  try {
    const sort = [{ field: 'user.createdAt', dir: 'desc' }];

    const data = await graphqlApi().query({
      query: CUSTOMERS,
      variables: {
        filter,
        sort,
        skip,
        take,
      },
    });
    return data.data.rootCustomers;
  } catch (error) {
    /** */
  }
}

const CUSTOMERS_BALANCE = gql`
  query rootAccountTransactionsTopCustomers($skip: Int, $take: Int) {
    rootAccountTransactionsTopCustomers(skip: $skip, take: $take) {
      _id
      name
      cpf
      user {
        createdAt
      }
      balance
    }
  }
`;

async function getCustomersBalance(skip, take) {
  try {
    const data = await graphqlApi().query({
      query: CUSTOMERS_BALANCE,
      variables: {
        skip,
        take,
      },
    });
    return data.data.rootAccountTransactionsTopCustomers;
  } catch (error) {
    /** */
  }
}

const CUSTOMER_COUNT = gql`
  query rootCustomerCount {
    rootCustomerCount
  }
`;

async function getCustomerCount() {
  try {
    const data = await graphqlApi().query({
      query: CUSTOMER_COUNT,
    });
    return data.data.rootCustomerCount;
  } catch (error) {
    /** */
  }
}

export default {
  getCustomer,
  getCustomers,
  getCustomersBalance,
  getCustomerCount,
};
