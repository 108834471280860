import React from 'react';
import './styles.scss';
import useCustomer from './useCustomer'
import Header from '../../components/header/Header';
import { CustomerList } from './CustomerList';
import { CustomerSkeleton } from './CustomerSkeleton';
import { CustomerFilter } from './CustomerFilter';
import Show from '../../components/show';

export default function Customer() {
  const { customers, hasMore, fetchMore, onSearch } = useCustomer();

  return (
    <div className="page-container container-fluid">
      <Header
        title="Clientes"
        subTitle={`Exibindo um total de ${customers?.length} clientes`}
        // onClickAdd={() => handleClickAdd()}
      />
      <CustomerFilter onSearch={(f) => onSearch(f)} />
      <Show if={customers?.length > 0}>
        <CustomerList
          data={customers}
          fetchMore={fetchMore}
          onHasMore={hasMore}
        />
      </Show>
      <Show if={customers?.length <= 0}>
        <CustomerSkeleton />
      </Show>
    </div>
  );
}
