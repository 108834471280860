import React from 'react';
import CompanyActions from './CompanyActions'

const ListItem = ({
  id,
  logo,
  name,
  cnpj,
  isActived,
  followerCount,
}) => (
  <tr>
    <td>
      <div
        className="table__avatar"
        style={{
          backgroundImage: `url(${logo})`,
          flex: 1,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          margin: '0 10px',
        }}
      />
    </td>
    <td>{name}</td>
    <td>{cnpj}</td>
    <td>{isActived ? 'Sim' : 'Não'}</td>
    <td>{followerCount}</td>

    {/* <td>
      <CompanyActions
        id={id}
      />
    </td> */}
  </tr>
);

export default ListItem;
