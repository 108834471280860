import React from 'react';
import './styles.scss';
import useCompany from './useCompany'
import Header from '../../components/header/Header';
import { CompanyList } from './CompanyList';
import { CompanySkeleton } from './CompanySkeleton';
import { CompanyFilter } from './CompanyFilter';
import Show from '../../components/show';

export default function Company() {
  const { companies, hasMore, fetchMore, onSearch } = useCompany();

  return (
    <div className="page-container container-fluid">
      <Header
        title="Empresas"
        subTitle={`Exibindo um total de ${companies?.length} clientes`}
        // onClickAdd={() => handleClickAdd()}
      />
      <CompanyFilter onSearch={(f) => onSearch(f)} />
      <Show if={companies?.length > 0}>
        <CompanyList
          data={companies}
          fetchMore={fetchMore}
          onHasMore={hasMore}
        />
      </Show>
      <Show if={companies?.length <= 0}>
        <CompanySkeleton />
      </Show>
    </div>
  );
}
