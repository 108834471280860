import { Router } from '@reach/router';
import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { ToastContainer } from 'react-toastify';

import Public from './Public';
import Panel from './Panel';

import Login from '../../pages/login';
import Dashboard from '../../pages/dashboard';
import Customer from '../../pages/customer';
import Company from '../../pages/company';
// import Payment from '../../pages/payment';
import CustomerForm from '../../pages/customer/CustomerForm';
import Withdraw from '../../pages/withdraw';

class Content extends React.PureComponent {
  render() {
    return (
      <Fragment>
        <Router>
          <Public
            path="/"
            container={<Login />}
            title={`${I18n.t('routes.login.pageTitle')} - ${I18n.t('application.name')}`}
          />
          <Public
            path={I18n.t('routes.login.url')}
            container={<Login />}
            title={`${I18n.t('routes.login.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={I18n.t('routes.dashboard.url')}
            container={<Dashboard />}
            title={`${I18n.t('routes.dashboard.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={I18n.t('routes.customer.url')}
            container={<Customer />}
            title={`${I18n.t('routes.customer.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={`${I18n.t('routes.customer.url')}/:id`}
            container={<CustomerForm />}
            title={`${I18n.t('routes.customer.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={`${I18n.t('routes.company.url')}`}
            container={<Company />}
            title={`${I18n.t('routes.company.pageTitle')} - ${I18n.t('application.name')}`}
          />

          {/* <Panel
            path={`${I18n.t('routes.payment.url')}`}
            container={<Payment />}
            title={`${I18n.t('routes.payment.pageTitle')} - ${I18n.t('application.name')}`}
          /> */}

          <Panel
            path={I18n.t('routes.withdraw.url')}
            container={<Withdraw />}
            title={`${I18n.t('routes.withdraw.pageTitle')} - ${I18n.t('application.name')}`}
          />

        </Router>
        <ToastContainer
          position="bottom-center"
          autoClose={2500}
          hideProgressBar
        />
      </Fragment>
    );
  }
}

export default Content;
