import { useState, useEffect } from 'react'
import CompanyApi from '../../app/api/company';

const useCompany = () => {
  const [company, setCompany] = useState();
  const [companies, setCompanies] = useState([]);
  const [take] = useState(20);
  const [skip, setSkip] = useState(0);
  const [oldCompaniesLength, setOldCompaniesLength] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [filter, setFilter] = useState([]);

  const getCompany = async (id) => {
    const data = await CompanyApi.getCompany(id);
    setCompany(data?.company)
  }

  const getCompanies = async () => {
    setSkip(skip + take);
    return await CompanyApi.getCompanies(filter, skip, take);
  }

  const fetchMore = async () => {
    setOldCompaniesLength(companies.length);
    const newList = await getCompanies();
    setCompanies([...companies, ...newList]);
    setSkip(skip + take);
  }

  const onSearch = async (filterForm) => {
    const filterData = []
    for (const [key, value] of Object.entries(filterForm)) {
      if (!!value) {
        filterData.push({
          field: key,
          value: value,
          operator: 'contains',
        })
      }
    }
    setFilter(filterData)
    setSkip(20);
    setOldCompaniesLength(0)
    const list = await CompanyApi.getCompanies(filterData, 0, take);
    setCompanies(list);
  }

  useEffect(() => {
    async function init() {
      const list = await getCompanies();
      setCompanies(list);
    }
    init()
  }, []);

  useEffect(() => {
    const diffCompaniesLength = companies?.length - oldCompaniesLength;
    setHasMore((diffCompaniesLength) % 10 === 0 && diffCompaniesLength > 0)
  }, [companies]);

  return { company, companies, hasMore, fetchMore, getCompany, getCompanies, onSearch }
}

export default useCompany
