import { MdDashboard } from 'react-icons/md';
import { FaUsers, FaMoneyCheckAlt, FaBuilding } from 'react-icons/fa';
import { RiMoneyDollarBoxFill } from 'react-icons/ri';

const SidebarLinks = [
  {
    name: 'routes.dashboard.pageTitle',
    icon: MdDashboard,
    url: 'routes.dashboard.url',
  },
  {
    name: 'routes.customer.pageTitle',
    icon: FaUsers,
    url: 'routes.customer.url',
  },
  {
    name: 'routes.company.pageTitle',
    icon: FaBuilding,
    url: 'routes.company.url',
  },
  // {
  //   name: 'routes.payment.pageTitle',
  //   icon: FaMoneyCheckAlt,
  //   url: 'routes.payment.url',
  // },
  {
    name: 'routes.withdraw.pageTitle',
    icon: RiMoneyDollarBoxFill,
    url: 'routes.withdraw.url',
  },
];

export default SidebarLinks;
