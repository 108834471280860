import React from 'react';

import FormWrapper from '../../components/shared/form/FormWrapper';
import LoginForm from './LoginForm';

export default function Login() {
  return (
    <FormWrapper>
      <LoginForm />
    </FormWrapper>
  );
}
