/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import './styles.scss';

export function CustomerFilter({ onSearch }) {
  const [filter, setFilter] = useState({
    cpf: '',
    name: '',
    email: '',
    mobileNumber: '',
    referral: '',
  })

  return (
    <div className="filter">
      <div className="filter__form__field">
        <span className="filter__form__field__label">CPF</span>
        <input
          className="filter__form__field__input"
          type="text"
          placeholder="CPF"
          maxLength="150"
          required
          value={filter.cpf}
          onChange={e => setFilter({ ...filter, ['cpf']: e.target.value.replace(/[^0-9]/g, '') })}
        />
      </div>

      <div className="filter__form__field">
        <span className="filter__form__field__label">Nome</span>
        <input
          className="filter__form__field__input"
          type="text"
          placeholder="Nome"
          maxLength="150"
          required
          value={filter.name}
          onChange={e => setFilter({ ...filter, ['name']: e.target.value })}
        />
      </div>

      <div className="filter__form__field">
        <span className="filter__form__field__label">E-mail</span>
        <input
          className="filter__form__field__input"
          type="text"
          placeholder="E-mail"
          maxLength="150"
          required
          value={filter.email}
          onChange={e => setFilter({ ...filter, ['email']: e.target.value })}
        />
      </div>

      <div className="filter__form__field">
        <span className="filter__form__field__label">Celular</span>
        <input
          className="filter__form__field__input"
          type="text"
          placeholder="Celular"
          maxLength="150"
          required
          value={filter.mobileNumber}
          onChange={e => setFilter({ ...filter, ['mobileNumber']: e.target.value.replace(/[^0-9]/g, '') })}
        />
      </div>

      <div className="filter__form__field">
        <span className="filter__form__field__label">Indicação</span>
        <input
          className="filter__form__field__input"
          type="text"
          placeholder="Indicação"
          maxLength="150"
          required
          value={filter.referral}
          onChange={e => setFilter({ ...filter, ['referral']: e.target.value })}
        />
      </div>

      <button
        type="button"
        className="btn-primary filter__form__button"
        onClick={() => onSearch(filter)}
      >
        Buscar
      </button>
    </div>
  );
}
