import React from 'react';
import { I18n } from 'react-redux-i18n';
import { IoIosEye } from 'react-icons/io';

const CustomerActions = ({
  id,
}) => {

  return (
    <div className="article__actions">
      <button type="button" className="article__actions__button">
        <a href={`${I18n.t('routes.customer.url')}/${id}`} target="_blank">
          <IoIosEye />
        </a>
      </button>
    </div>
  );
}

export default CustomerActions;
